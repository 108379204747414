<template>
	<LinkTile
		v-if="to"
		:aria-labelledby="`content-card-${_uid}`"
		class="c-content-card__container relative"
		v-bind="{ to, target }"
	>
		<div
			class="
				c-content-card
				rounded-lg
				overflow-hidden
				h-full
				flex flex-col
			"
			:class="light ? 'bg-white' : 'bg-primary-medium'"
		>
			<div
				v-if="image && image.cropUrl"
				:class="[
					'c-content-card__image',
					'relative w-full h-0 overflow-hidden',
					'bg-primary-light ',
				]"
				:style="{
					'padding-top': `${
						(image.aspectRatio
							? 1 / image.aspectRatio
							: image.height / image.width) * 100
					}%`,
				}"
			>
				<UmbracoImageExt
					:widths="[320, 480, 640, 480 * 2, 640 * 2]"
					:alt="image.altText"
					:source-url="image.cropUrl"
					:source-width="image.width"
					:source-height="image.height"
					:aspect-ratio="
						image.aspectRatio || image.width / image.height
					"
					:class="[
						'absolute top-1/2 left-1/2',
						'w-full bg-primary-medium',
						'duration-500 ease-smooth-out',
					]"
				/>
			</div>

			<div
				v-if="video && video.provider"
				:class="[
					'c-content-card__image',
					'relative w-full h-0 overflow-hidden',
					'bg-primary-light',
				]"
				:style="{
					'padding-top': `${
						(video.aspectRatio ? 1 / video.aspectRatio : 3 / 4) *
						100
					}%`,
				}"
			>
				<CookieInformationBlocker
					class="absolute top-0 left-0 w-full h-full"
					:categories="video.cookieCategories"
				>
					<VideoPlayer
						class="absolute top-0 left-0 w-full h-full"
						:picker="video"
					/>
				</CookieInformationBlocker>
			</div>

			<div
				:class="[
					'c-content-card__content',
					'flex flex-col justify-start flex-1',

					{
						'p-md >=768:p-sm >=768:pb-md': size === 'small',
						'p-md py-sm': size === 'medium',
						'mb-sm': $scopedSlots.after,
					},
				]"
			>
				<span
					:class="{
						'mb-md': $scopedSlots.after || !$scopedSlots.default,
					}"
				>
					<div
						v-if="$scopedSlots.before"
						class="text-h8 uppercase mb-2xs >=768:mb-12"
					>
						<slot name="before"></slot>
					</div>

					<div :id="`content-card-${_uid}`">
						<slot name="title">
							<BaseH3 v-if="name" v-text="name"></BaseH3>
						</slot>
					</div>

					<div v-if="$scopedSlots.default" class="text-body mt-xs">
						<slot></slot>
					</div>
				</span>

				<div
					v-if="$scopedSlots.after"
					class="text-h8 mt-xs absolute bottom-md"
				>
					<slot name="after"></slot>
				</div>
			</div>
		</div>

		<BaseButton
			tag="div"
			:class="[
				'c-content-card__arrow-button',
				'absolute top-full left-1/2',
				'transform -translate-y-1/2 -translate-x-1/2',
				'w-fit z-10 mx-auto',
			]"
		>
			<template #icon>
				<SvgArrowIcon class="w-14 h-12 text-white" />
			</template>
		</BaseButton>
	</LinkTile>
</template>

<script>
import LinkTile from '~/citi-baseline/components/LinkTile';
import VideoPlayer from '~/citi-baseline/components/VideoPlayer/VideoPlayer';
import CookieInformationBlocker from '~/components/shared/CookieInformationBlockerCustom';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import SvgArrowIcon from '~/assets/svgs/arrow-icon.svg?inline';

export default {
	name: 'ContentCard',

	components: {
		LinkTile,
		VideoPlayer,
		CookieInformationBlocker,
		UmbracoImageExt,
		SvgArrowIcon,
	},

	props: {
		image: {
			type: Object,
			required: false,
		},
		video: {
			type: Object,
			required: false,
		},
		to: {
			type: String,
			required: true,
		},
		target: {
			type: String,
			required: false,
		},
		name: {
			type: String,
			required: false,
		},
		overline: {
			type: String,
			required: false,
		},
		light: {
			type: Boolean,
			required: false,
		},
		size: {
			type: String,
			default: 'medium',
			validator: (value) => ['small', 'medium', 'custom'].includes(value),
		},
	},
};
</script>

<style lang="postcss">
.c-content-card__container .c-umbraco-image {
	@apply transform -translate-x-1/2 -translate-y-1/2;
}

.c-content-card__container[data-hover='hover'] .c-umbraco-image {
	@apply scale-110;
}
.c-content-card__content h3 {
	@apply break-words;
}
</style>
